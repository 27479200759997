import React from 'react';
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { CheckCircle as CheckCircleIcon } from '@mui/icons-material';

export default function Pricing() {
  const features = [
    "24 hands-on Azure infrastructure exercises",
    "Production-ready code examples",
    "Future updates and improvements",
    "Completion certificate"
  ];

  const foundingMemberBenefits = [
    "Access content as it's created",
    "Influence module topics and real-world scenarios",
    "Lock in best-available pricing",
    "Receive lifetime updates",
  ];

  const supportPackage = [
    "60-minute 1:1 consultation",
    "Priority email support",
    "Custom scenario review",
    "Available throughout your learning journey"
  ];

  return (
    <Box sx={{ py: 8, bgcolor: 'background.default' }}>
      <Container maxWidth="md">
        <Card
          sx={{
            boxShadow: 2,
            borderRadius: 4,  // Increased border radius
            overflow: 'hidden'
          }}
        >
          <CardContent sx={{ p: 0 }}>
            {/* Title with blue background */}
            <Box
              sx={{
                bgcolor: 'primary.main',
                py: 3,  // Increased padding
                borderBottom: '4px solid',
                borderColor: 'warning.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography variant="h4" component="h2" align="center"
                sx={{
                  fontWeight: 400,  // Lighter weight
                  color: 'white',
                }}
              >
                Early Access Offer
              </Typography>
            </Box>

            {/* Pricing content */}
            <Box sx={{ px: 4, py: 5 }}>
              {/* Regular Price - Moved to top */}
              <Typography
                variant="h6"
                align="center"
                sx={{
                  color: 'text.secondary',
                  position: 'relative',
                  mb: 2,
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    left: '20%',
                    right: '20%',
                    top: '50%',
                    height: '1px',
                    background: 'text.secondary',
                  }
                }}
              >
                Regular Price: €499
              </Typography>

              {/* Launch Price */}
              <Typography
                variant="h3"
                align="center"
                sx={{
                  color: 'primary.main',
                  fontWeight: 700,
                  mb: 3,
                  fontSize: '2.5rem'
                }}
              >
                Launch Price: €399.20
              </Typography>

              {/* Promo Code */}
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    bgcolor: 'warning.main',
                    color: 'warning.contrastText',
                    py: 1,
                    px: 3,
                    borderRadius: 2,
                    fontWeight: 500
                  }}
                >
                  with code TITANPIONEER
                </Typography>
              </Box>

              {/* Notes section */}
              <Box sx={{ mb: 4, textAlign: 'center' }}>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    mb: 0.25
                  }}
                >
                  Note:
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    mb: 0.25
                  }}
                >
                  This special pricing is available only until the course launch in mid-December.
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontStyle: 'italic',
                    mb: 0.25
                  }}
                >
                  All prices are net (excluding VAT).
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontStyle: 'italic'
                  }}
                >
                  VAT will be added based on your location at checkout.
                </Typography>
              </Box>

              {/* Sections with checkmarks */}
              <Box sx={{
                '& .MuiListItem-root': {
                  py: 1.5,  // Increased spacing between items
                  borderBottom: '1px solid',
                  borderColor: 'divider'
                },
                '& .MuiListItemIcon-root': {
                  minWidth: 32,
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                    fontSize: '1.2rem'
                  }
                }
              }}>
                {/* Section headers with more prominence */}
                <Typography variant="h6" sx={{
                  fontWeight: 600,
                  mb: 2,
                  mt: 4,
                  textAlign: 'center'
                }}>
                  Join as a Founding Member to:
                </Typography>
                <List>
                  {foundingMemberBenefits.map((benefit, index) => (
                    <ListItem key={index} sx={{ py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon sx={{ color: 'primary.main', fontSize: '1.1rem' }} />
                      </ListItemIcon>
                      <ListItemText primary={benefit} />
                    </ListItem>
                  ))}
                </List>

                {/* Support Package */}
                <Typography
                  variant="h6"
                  align="center"
                  sx={{
                    mt: 4,
                    mb: 2,
                    fontWeight: 700,  // Increased from 600
                    fontSize: '1.25rem',  // Slightly larger
                    color: 'primary.main',  // Using primary color (blue)
                    borderBottom: '2px solid',  // Adding underline
                    borderColor: 'warning.main',  // Yellow underline
                    pb: 1,  // Padding for underline
                    width: 'fit-content',  // Make border only as wide as text
                    mx: 'auto'  // Center the element
                  }}
                >
                  Private Implementation Support Package:
                </Typography>
                <List>
                  {supportPackage.map((item, index) => (
                    <ListItem key={index} sx={{ py: 0.5 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon sx={{ color: 'primary.main', fontSize: '1.1rem' }} />
                      </ListItemIcon>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>

                {/* Features section */}
                <Typography variant="h6" align="center" sx={{ mt: 4, mb: 2, fontWeight: 600 }}>
                  Includes:
                </Typography>
                <List>
                  {features.map((feature, index) => (
                    <ListItem key={index} sx={{ py: 1 }}>
                      <ListItemIcon sx={{ minWidth: 36 }}>
                        <CheckCircleIcon sx={{ color: 'primary.main', fontSize: '1.1rem' }} />
                      </ListItemIcon>
                      <ListItemText primary={feature} />
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
} 